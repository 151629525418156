import React from 'react'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'

import { get } from 'lodash'

import { Button } from '../../components/atoms/button/Button'
import { Layout } from '../../components/layout'
import { OAuthBlock } from '../../components/sections/OAuthBlock'

export const oauthLocaleKeys = {
  signup: {
    title: 'signUpScreen.title',
    href: `${process.env.NEXT_PUBLIC_WEBAPP_URL}/auth/signin`,
    description: 'signUpScreen.footer.label',
    buttonTitle: 'signUpScreen.footer.button',
  },
  signin: {
    title: 'signInScreen.title',
    href: `${process.env.NEXT_PUBLIC_WEBAPP_URL}/auth/signup`,
    description: 'signInScreen.footer.label',
    buttonTitle: 'signInScreen.footer.button',
  },
}

export const OAuth = ({ type }: { type: string }) => {
  const { formatMessage } = useIntl()
  const { query, isReady } = useRouter()

  const getLocalString = (key: string, prop: string) =>
    get(oauthLocaleKeys, `${key}.${prop}`, 'empty')

  if (!isReady) return null

  return (
    <Layout>
      <div className="m-auto text-center max-w-sm px-5">
        {query?.code ? (
          <div>
            <p className="font-semibold text-white">
              {formatMessage({ id: 'authScreen.code.title' })}
            </p>
            <p>{formatMessage({ id: 'authScreen.code.description' })}</p>
          </div>
        ) : (
          <h1>{formatMessage({ id: getLocalString(String(type), 'title') })}</h1>
        )}
        <OAuthBlock authType={type as any} />

        <div className="w-full max-w-xs m-auto space-y-4">
          <p className="text-white">
            {formatMessage({ id: getLocalString(String(type), 'description') })}
          </p>
          <Button
            buttonType="secondary"
            href={{
              pathname: getLocalString(String(type), 'href'),
              query,
            }}
            fullWidth
          >
            {formatMessage({
              id: getLocalString(String(type), 'buttonTitle'),
            })}
          </Button>
        </div>
        {type === 'signup' && (
          <div className="py-14">
            <p className="text-sm">{formatMessage({ id: 'signUpScreen.policy' })}</p>
          </div>
        )}
      </div>
    </Layout>
  )
}
